<nz-layout>
  <nz-header [ngStyle]="{'background-color': this.getPlatformColor()}">
    <img [src]="this.getPlatformImage()">
  </nz-header>

  <nz-content class="container">
    <nz-spin *ngIf="this.isLoading" nzSimple [nzSize]="'large'"></nz-spin>
    <ng-container *ngIf="this.noData">
      <h1 class="header center mt8">
        {{ 'common.table.noResult' | translate}}
      </h1>
    </ng-container>
    <ng-container *ngIf="this.statistics">
      <section *ngIf="this.statistics.clubsCount || this.statistics.athletesCount">
        <h1 class="header">
          {{this.statistics.name}}
        </h1>
        <p class="text">
          {{ 'statistics.countsText' | translate: this.lineChart.summary }}
        </p>
        <div class="filters">
          <div class="filters__item">
            <label>{{ 'statistics.labels.sport' | translate}}:</label>
            <nz-select
            nzShowSearch
            [nzDropdownMatchSelectWidth]="false"
            [(ngModel)]="this.lineChart.selectedSport"
            (ngModelChange)="this.changeLineChartFilter()"
            nzPlaceHolder="{{ 'common.buttons.select' | translate}}">
              <nz-option [nzValue]="0" nzLabel="{{ 'common.constants.sportTypes.all' | translate}}"></nz-option>
              <nz-option *ngFor="let sport of this.sportsList" [nzValue]="sport.id" [nzLabel]="sport.name"></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="chart">
          <canvas baseChart
            [datasets]="this.lineChart.data"
            [labels]="this.lineChart.labels"
            [options]="this.lineChart.options"
            [colors]="this.lineChart.colors"
            [legend]="this.lineChart.legend"
            [chartType]="this.lineChart.type"
            (chartHover)="this.chartHovered($event)"
            (chartClick)="this.chartClicked($event)">
          </canvas>
        </div>
      </section>

      <section *ngIf="this.statistics.sportFunds">
        <div class="grid">
          <div class="grid__chart">
            <div class="filters center">
              <div class="filters__item">
                <label>{{ 'statistics.labels.year' | translate}}:</label>
                <nz-select
                id="sportFundsYear"
                [(ngModel)]="this.pieChart.selectedYear"
                (ngModelChange)="this.changePieChartFilter()"
                nzPlaceHolder="{{ 'common.buttons.select' | translate}}">
                  <nz-option *ngFor="let year of this.yearsList" [nzValue]="year" [nzLabel]="year"></nz-option>
                </nz-select>
              </div>
            </div>
            <div class="filters center">
              <button nz-button
              [nzType]="this.pieChart.selectedStatus === 0 ? 'primary' : 'default'"
              (click)="this.changePieChartStatus(0)">
              {{ 'statistics.labels.allocated' | translate}}
              </button>
              <button nz-button
              [nzType]="this.pieChart.selectedStatus === 1 ? 'primary' : 'default'"
              (click)="this.changePieChartStatus(1)">
              {{ 'statistics.labels.paid' | translate}}
              </button>
              <button nz-button
              [nzType]="this.pieChart.selectedStatus === 2 ? 'primary' : 'default'"
              (click)="this.changePieChartStatus(2)">
              {{ 'statistics.labels.justified' | translate}}
              </button>
            </div>
            <div class="chart">
              <canvas baseChart
                [data]="this.pieChart.data"
                [labels]="this.pieChart.labels"
                [options]="this.pieChart.options"
                [colors]="this.pieChart.colors"
                [legend]="this.pieChart.legend"
                [chartType]="this.pieChart.type"
                (chartHover)="this.chartHovered($event)"
                (chartClick)="this.chartClicked($event)">
              </canvas>
            </div>
          </div>
          <div class="grid__text">
            <h1 class="header">
              {{ 'statistics.headerSport' | translate}}
            </h1>
            <p class="text">
              {{ 'statistics.sportsFundsText' | translate: this.pieChart.summary }}
            </p>
            <a (click)="this.openSportFundsModal()">
              {{ 'common.buttons.seeMore' | translate}}
            </a>
          </div>
        </div>
      </section>

      <section *ngIf="this.statistics.clubFunds">
        <h1 class="header">
          {{ 'statistics.headerClub' | translate}}
        </h1>
        <p class="text">
          {{ 'statistics.clubFundsText' | translate: this.barChartHorizontal.summary }}
        </p>
        <div class="filters">
          <a (click)="this.openClubFundsModal()">
            {{ 'common.buttons.seeMore' | translate}}
          </a>
        </div>
        <div class="filters">
          <div class="filters__item">
            <label>{{ 'statistics.labels.year' | translate}}:</label>
            <nz-select
            id="clubFundsYear"
            [(ngModel)]="this.barChartHorizontal.selectedYear"
            (ngModelChange)="this.changeBarChartHorizontalFilter()"
            nzPlaceHolder="{{ 'common.buttons.select' | translate}}">
              <nz-option *ngFor="let year of this.yearsList" [nzValue]="year" [nzLabel]="year"></nz-option>
            </nz-select>
          </div>
          <div class="filters__item">
            <label>{{ 'statistics.labels.sport' | translate}}:</label>
            <nz-select
            nzShowSearch
            [nzDropdownMatchSelectWidth]="false"
            [(ngModel)]="this.barChartHorizontal.selectedSport"
            (ngModelChange)="this.changeBarChartHorizontalFilter()"
            nzPlaceHolder="{{ 'common.buttons.select' | translate}}">
              <nz-option [nzValue]="0" nzLabel="{{ 'common.constants.sportTypes.all' | translate}}"></nz-option>
              <nz-option *ngFor="let sport of this.sportsList" [nzValue]="sport.id" [nzLabel]="sport.name"></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="chart">
          <canvas baseChart
            [datasets]="this.barChartHorizontal.data"
            [labels]="this.barChartHorizontal.labels"
            [options]="this.barChartHorizontal.options"
            [colors]="this.barChartHorizontal.colors"
            [legend]="this.barChartHorizontal.legend"
            [chartType]="this.barChartHorizontal.type"
            (chartHover)="this.chartHovered($event)"
            (chartClick)="this.chartClicked($event)">
          </canvas>
        </div>
      </section>

      <section *ngIf="this.statistics.labelFunds">
        <h1 class="header">
          {{ 'statistics.headerLabel' | translate}}
        </h1>
        <p class="text">
          {{ 'statistics.labelFundsText' | translate: this.barChartVertical.summary }}
        </p>
        <div class="filters">
          <a (click)="this.openLabelFundsModal()">
            {{ 'common.buttons.seeMore' | translate}}
          </a>
        </div>
        <div class="filters">
          <div class="filters__item">
            <label>{{ 'statistics.labels.year' | translate}}:</label>
            <nz-select
            id="labelFundsYear"
            [(ngModel)]="this.barChartVertical.selectedYear"
            (ngModelChange)="this.changeBarChartVerticalFilter()"
            nzPlaceHolder="{{ 'common.buttons.select' | translate}}">
              <nz-option *ngFor="let year of this.yearsList" [nzValue]="year" [nzLabel]="year"></nz-option>
            </nz-select>
          </div>
          <div class="filters__item">
            <label>{{ 'statistics.labels.sport' | translate}}:</label>
            <nz-select
            nzShowSearch
            [nzDropdownMatchSelectWidth]="false"
            [(ngModel)]="this.barChartVertical.selectedSport"
            (ngModelChange)="this.changeBarChartVerticalSportFilter()"
            nzPlaceHolder="{{ 'common.buttons.select' | translate}}">
              <nz-option [nzValue]="0" nzLabel="{{ 'common.constants.sportTypes.all' | translate}}"></nz-option>
              <nz-option *ngFor="let sport of this.sportsList" [nzValue]="sport.id" [nzLabel]="sport.name"></nz-option>
            </nz-select>
          </div>
          <div *ngIf="this.barChartVertical.selectedSport" class="filters__item">
            <label>{{ 'statistics.labels.club' | translate}}:</label>
            <nz-select
            nzShowSearch
            [nzDropdownMatchSelectWidth]="false"
            [(ngModel)]="this.barChartVertical.selectedClub"
            (ngModelChange)="this.changeBarChartVerticalFilter()"
            nzPlaceHolder="{{ 'common.buttons.select' | translate}}">
              <nz-option *ngFor="let club of this.getClubsList()" [nzValue]="club.uuid" [nzLabel]="club.name"></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="chart">
          <canvas baseChart
            [datasets]="this.barChartVertical.data"
            [labels]="this.barChartVertical.labels"
            [options]="this.barChartVertical.options"
            [colors]="this.barChartVertical.colors"
            [legend]="this.barChartVertical.legend"
            [chartType]="this.barChartVertical.type"
            (chartHover)="this.chartHovered($event)"
            (chartClick)="this.chartClicked($event)">
          </canvas>
        </div>
      </section>
    </ng-container>

  </nz-content>

  <nz-footer>
    <div class="text">
      {{ 'statistics.footerText' | translate}}
    </div>
  </nz-footer>

</nz-layout>
