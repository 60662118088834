import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MODULE_PATH_OPEN_CITY } from '../openCity/openCity.routes';

const MainRoutes: Routes = [
  { path: '',   redirectTo: MODULE_PATH_OPEN_CITY,   pathMatch: 'full' },
  { path: '**', redirectTo: MODULE_PATH_OPEN_CITY,   pathMatch: 'full'}
];

export const MainRouting: ModuleWithProviders<any> = RouterModule.forRoot(MainRoutes);
