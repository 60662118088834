<div>
  <div class="filters">
    <div class="filters__item">
      <label>{{ 'statistics.labels.year' | translate}}:</label>
      <nz-select
      [(ngModel)]="this.filters.selectedYear"
      (ngModelChange)="this.changeFilter()"
      nzPlaceHolder="{{ 'common.buttons.select' | translate}}">
        <nz-option *ngFor="let year of this.yearsList" [nzValue]="year" [nzLabel]="year"></nz-option>
      </nz-select>
    </div>
    <div class="filters__search">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input nz-input [(ngModel)]="this.filters.search" (ngModelChange)="this.changeFilter()" placeholder="{{ 'common.buttons.search' | translate}}" />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
    </div>
  </div>
  <nz-table
  #table
  nzSize="small"
  [nzLoading]="this.isLoading"
  [nzFrontPagination]="true"
  [nzShowPagination]="true"
  [nzData]="this.tableData"
  nzNoResult="{{ 'common.table.noResult' | translate}}">
    <thead>
      <tr>
        <th>{{ 'statistics.labels.name' | translate}}</th>
        <th>{{ 'statistics.labels.allocated' | translate}}</th>
        <th>{{ 'statistics.labels.paid' | translate}}</th>
        <th>{{ 'statistics.labels.justified' | translate}}</th>
        <th>{{ 'statistics.labels.athletesCount' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of table.data; let i = index;">
        <td>{{data.name}}</td>
        <td>{{this.utilService.formatNumber(data.allocated)}}{{this.utilService.getYearCurrency(this.filters.selectedYear)}}</td>
        <td>{{this.utilService.formatNumber(data.paid)}}{{this.utilService.getYearCurrency(this.filters.selectedYear)}}</td>
        <td>{{this.utilService.formatNumber(data.justified)}}{{this.utilService.getYearCurrency(this.filters.selectedYear)}}</td>
        <td>{{data.athletesCount ? this.utilService.formatNumber(data.athletesCount, false) : '-'}}</td>
      </tr>
    </tbody>
  </nz-table>
</div>
<div *nzModalFooter>
  <button nz-button nzType="default" (click)="this.cancel()">{{ 'common.buttons.close' | translate}}</button>
</div>
