import { Component, OnInit } from '@angular/core';

import { UtilService } from '../../../common';

@Component({
  selector: 'main-container',
  templateUrl: './mainContainer.html',
  styleUrls: ['./mainContainer.scss']
})
export class MainContainerComponent implements OnInit{

  public homeComponent: boolean = false;

  public statisticsComponent: boolean = false;

  constructor(public utilService: UtilService) {

  }

  public ngOnInit(): void {
    let hostname =  window.location.hostname;
    let array = hostname.split('.');

    if(array.length == 3){
      this.homeComponent = true;
    } else if(array.length == 4){
      this.statisticsComponent = true;
    }

	}

}
