import {Component, OnInit, Input} from '@angular/core';
import * as moment from 'moment';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { UtilService } from 'src/app/common';

@Component({
    selector: 'sport-funds-modal',
    templateUrl: 'sportFunds.html',
    styleUrls: ["./sportFunds.scss"]
  })
  export class SportFundsModal implements OnInit {

    @Input() data: any;

    @Input() sports: any;

    public tableData: Array<any> = [];

    public isLoading: boolean = false;

    public filters = {
      selectedYear: moment().year(),
      search: null
    };

    public yearsList = [];

    constructor(private modal: NzModalRef, public utilService: UtilService) { }

    public ngOnInit() {
      this.yearsList = this.utilService.getYearsList();
      this.changeFilter();
    }

    public changeFilter(): void {
      let yearData = this.data[this.filters.selectedYear];
      this.tableData = [];
      if(yearData) {
        delete yearData.all;
        let data = Object.values(yearData);
        data.sort((a: any, b: any) => b.allocated - a.allocated);
        data.forEach((item: any) => {
          let condition = this.filters.search ? item.name.toLowerCase().includes(this.filters.search.toLowerCase()) : true;
          if(condition){
            let club = this.sports.find(el => el.name === item.name);
            this.tableData.push({
              ...item,
              athletesCount: club ? club.athletesCount : null
            });
          }
        });
      }
    }

    public cancel(): void {
      this.modal.destroy(false);
    }

  }
