import { NgModule } from '@angular/core';
import { CommonModule as AnCommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';
import * as moment from 'moment';
import 'moment/min/locales';

import { SimpleRestManagerService } from './services/rest/simpleRestManager.service';
import { UtilService } from './services/util/util.service';

import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';

import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  imports: [
    AnCommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],

  exports: [
    AnCommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    ChartsModule,

    NzLayoutModule,
    NzButtonModule,
    NzSelectModule,
    NzInputModule,
    NzTableModule,
    NzModalModule,
    NzSpinModule,
    NzIconModule,
    NzCardModule

  ],
  entryComponents: [

  ],

  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
    UtilService,
    SimpleRestManagerService,
  ],

  declarations: [

  ]
})
export class CommonModule {

  constructor(translate: TranslateService) {
    this.initI18n(translate);
  }

  /**
   * Initialize default application language
   * @param <TranslateService> translate
   */
  private initI18n(translate: TranslateService): void {
    translate.setDefaultLang('hr');

    let appLang = localStorage.getItem("APP_LANG");
    if (!appLang) {
      appLang = 'hr';
      localStorage.setItem("APP_LANG", appLang);
    }
    translate.use(appLang);
    moment.locale([appLang, 'en']);
  }
}

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  let hostname =  window.location.hostname;
  let array = hostname.split('.');
  let platform = 'sport';

  if(array.length == 4){
    platform = hostname.split('.')[1];
  }

  return new TranslateHttpLoader(http, './assets/i18n/' + platform + '/', '.json?v=3');
}
