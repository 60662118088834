import { Component, ViewChild, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import * as moment from 'moment';

import { SimpleRestManagerService, UtilService } from '../../../common';
import { SportFundsModal } from '../../modals/sportFunds/sportFunds.modal';
import { ClubFundsModal } from '../../modals/clubFunds/clubFunds.modal';
import { LabelFundsModal } from '../../modals/labelFunds/labelFunds.modal';

@Component({
  selector: 'statistics',
  templateUrl: './statistics.html',
  styleUrls: ['./statistics.scss']
})
export class StatisticsComponent implements OnInit{

  public isLoading: boolean = false;

  public noData: boolean = false;

  public subdomain: string;

  public platform: string;

  public statistics: any;

  public yearsList = [];

  public sportsList = [];

  public allClubs = [];

  public lineChart = {
    summary: {
      name: '',
      clubsCount: '0',
      athletesCount: '0'
    },
    selectedSport: 0,
    data: [
      { data: [], label: '', fill: true },
      { data: [], label: '', yAxisID: 'y-axis-1', fill: true }
    ],
    labels: [],
    options: {
      responsive: true,
      aspectRatio: 3,
      legend: {
        position: 'bottom'
      },
      scales: {
        xAxes: [{}],
        yAxes: [
          {
            id: 'y-axis-0',
            position: 'left',
            ticks: {
              beginAtZero: true,
              precision: 0,
              fontColor: '#108ee9',
              callback: function(value) {
                let result = '\\d(?=(\\d{3})+$)';
                let pom = value ? value : 0;
                let num = pom.toFixed(Math.max(0, ~~0));

                return num.replace(new RegExp(result, 'g'), '$&.');
              }
            },
            gridLines: {
              //color: 'rgba(16, 142, 233, 0.2)'
            }
          },
          {
            id: 'y-axis-1',
            position: 'right',
            ticks: {
              beginAtZero: true,
              precision: 0,
              fontColor: '#C9DA3E',
              callback: function(value) {
                let result = '\\d(?=(\\d{3})+$)';
                let pom = value ? value : 0;
                let num = pom.toFixed(Math.max(0, ~~0));

                return num.replace(new RegExp(result, 'g'), '$&.');
              }
            },
            gridLines: {
              display: false
              //color: 'rgba(201, 218, 62, 0.2)'
            }
          }
        ]
      }
    },
    colors: [
      { //#108ee9
        backgroundColor: 'rgba(16, 142, 233, 0.2)',
        borderColor: 'rgba(16, 142, 233, 1)',
        pointBackgroundColor: 'rgba(16, 142, 233, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(16, 142, 233, 1)'
      },
      { //#C9DA3E
        backgroundColor: 'rgba(201, 218, 62, 0.2)',
        borderColor: 'rgba(201, 218, 62, 1)',
        pointBackgroundColor: 'rgba(201, 218, 62, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(201, 218, 62, 1)'
      }
    ],
    legend: true,
    type: 'line'
  };

  public pieChart = {
    summary: {
      name: '',
      allocatedSum: '0',
      paidSum: '0',
      justifiedSum: '0',
      sportsCount: '0',
      currency: ''
    },
    selectedYear: moment().year(),
    selectedStatus: 0,
    data: [],
    labels: [],
    options: {
      responsive: true,
      aspectRatio: 2,
      legend: {
        position: 'bottom'
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            var label = data.labels[tooltipItem.index] || '';
            var select = document.getElementById("sportFundsYear");
            var year = select.querySelector(".ant-select-selection-item").getAttribute('title');
            var currency = parseInt(year) > 2022 ? 'eur' : 'kn';

            if (label) {
                label += ': ';
            }
            label += formatNumber(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) + currency;
            return label;

            function formatNumber(number: number): string{
              let result = '\\d(?=(\\d{3})+\\D)';
              let pom = number ? number : 0;
              let num = pom.toFixed(Math.max(0, ~~2));

              return num.replace('.', ',').replace(new RegExp(result, 'g'), '$&.');
            }
          }
        }
      }
    },
    colors: [
      {
        backgroundColor: [
          'rgba(129, 212, 250, 1)', //#81D4FA
          'rgba(16, 142, 233, 1)', //#108ee9
          'rgba(1, 87, 155, 1)', //#01579B
          'rgba(1, 44, 78, 1)', //#012C4E
          'rgba(102, 53, 143, 1)', //#66358F
          'rgba(141, 53, 143, 1)',//8D358F
          'rgba(235, 200, 68, 1)', //#EBC844
          'rgba(173, 164, 9, 1)', //#ADA409
          'rgba(201, 218, 62, 1)', //#C9DA3E
          'rgba(225, 245, 254, 1)' //#E1F5FE
        ],
        hoverBackgroundColor: [
          'rgba(129, 212, 250, 0.8)', //#81D4FA
          'rgba(16, 142, 233, 0.8)', //#108ee9
          'rgba(1, 87, 155, 0.8)', //#01579B
          'rgba(1, 44, 78, 0.8)', //#012C4E
          'rgba(102, 53, 143, 0.8)', //#66358F
          'rgba(141, 53, 143, 0.8)',//8D358F
          'rgba(235, 200, 68, 0.8)', //#EBC844
          'rgba(173, 164, 9, 0.8)', //#ADA409
          'rgba(201, 218, 62, 0.8)', //#C9DA3E
          'rgba(225, 245, 254, 0.8)' //#E1F5FE
        ],
      }
    ],
    legend: true,
    type: 'pie'
  };

  public barChartHorizontal = {
    summary: {
      name: '',
      allocatedSum: '0',
      paidSum: '0',
      justifiedSum: '0',
      clubsCount: '0',
      currency: ''
    },
    selectedYear: moment().year(),
    selectedSport: 0,
    data: [
      { data: [], label: 'Dodjeljeno sredstava' },
      { data: [], label: 'Isplaćena sredstva' },
      { data: [], label: 'Opravdana sredstva' }
    ],
    labels: [],
    options: {
      responsive: true,
      aspectRatio: 1.5,
      legend: {
        position: 'bottom'
      },
      scales: {
        xAxes:[
          {
            ticks: {
              beginAtZero: true,
              callback: function(value) {
                let result = '\\d(?=(\\d{3})+\\D)';
                let pom = value ? value : 0;
                let num = pom.toFixed(Math.max(0, ~~2));

                return num.replace('.', ',').replace(new RegExp(result, 'g'), '$&.');
              }
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: true
            },
            ticks: {
              callback: function(value) {
                if (value.length > 30) {
                  return value.substr(0, 30) + '...'; //truncate
                } else {
                  return value
                }
              }
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            var select = document.getElementById("clubFundsYear");
            var year = select.querySelector(".ant-select-selection-item").getAttribute('title');
            var currency = parseInt(year) > 2022 ? 'eur' : 'kn';

            if (label) {
                label += ': ';
            }
            label += formatNumber(tooltipItem.xLabel) + currency;
            return label;

            function formatNumber(number: number): string{
              let result = '\\d(?=(\\d{3})+\\D)';
              let pom = number ? number : 0;
              let num = pom.toFixed(Math.max(0, ~~2));

              return num.replace('.', ',').replace(new RegExp(result, 'g'), '$&.');
            }
          }
        }
      }
    },
    colors: [
      { //#01579B
        backgroundColor: 'rgba(1, 87, 155, 1)',
        borderColor: 'rgba(1, 87, 155, 1)',
        hoverBackgroundColor: 'rgba(1, 87, 155, 0.8)'
      },
      { //#108ee9
        backgroundColor: 'rgba(16, 142, 233, 1)',
        borderColor: 'rgba(16, 142, 233, 1)',
        hoverBackgroundColor: 'rgba(16, 142, 233, 0.8)'
      },
      { //#62e422
        backgroundColor: 'rgba(98, 228, 34, 1)',
        borderColor: 'rgba(98, 228, 34, 1)',
        hoverBackgroundColor: 'rgba(98, 228, 34, 0.8)'
      },
      // { //#81D4FA
      //   backgroundColor: 'rgba(129, 212, 250, 1)',
      //   borderColor: 'rgba(129, 212, 250, 1)',
      //   hoverBackgroundColor: 'rgba(129, 212, 250, 0.8)'
      // }
    ],
    legend: true,
    type: 'horizontalBar'
  };

  public barChartVertical = {
    summary: {
      name: '',
      allocatedSum: '0',
      paidSum: '0',
      justifiedSum: '0',
      labelsCount: '0',
      currency: ''
    },
    selectedYear: moment().year(),
    selectedSport: 0,
    selectedClub: null,
    data: [
      { data: [], label: 'Dodjeljeno sredstava' },
      { data: [], label: 'Isplaćena sredstva' },
      { data: [], label: 'Opravdana sredstva' }
    ],
    labels: [],
    options: {
      responsive: true,
      aspectRatio: 2,
      legend: {
        position: 'bottom'
      },
      scales: {
        xAxes:[
          {
            gridLines: {
              display: false
            },
            ticks: {
              callback: function(value) {
                if (value.length > 35) {
                  return value.substr(0, 35) + '...'; //truncate
                } else {
                  return value
                }
              }
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function(value) {
                let result = '\\d(?=(\\d{3})+\\D)';
                let pom = value ? value : 0;
                let num = pom.toFixed(Math.max(0, ~~2));

                return num.replace('.', ',').replace(new RegExp(result, 'g'), '$&.');
              }
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            var select = document.getElementById("labelFundsYear");
            var year = select.querySelector(".ant-select-selection-item").getAttribute('title');
            var currency = parseInt(year) > 2022 ? 'eur' : 'kn';

            if (label) {
                label += ': ';
            }
            label += formatNumber(tooltipItem.yLabel) + currency;
            return label;

            function formatNumber(number: number): string{
              let result = '\\d(?=(\\d{3})+\\D)';
              let pom = number ? number : 0;
              let num = pom.toFixed(Math.max(0, ~~2));

              return num.replace('.', ',').replace(new RegExp(result, 'g'), '$&.');
            }
          }
        }
      }
    },
    colors: [
      { //#01579B
        backgroundColor: 'rgba(1, 87, 155, 1)',
        borderColor: 'rgba(1, 87, 155, 1)',
        hoverBackgroundColor: 'rgba(1, 87, 155, 0.8)'
      },
      { //#108ee9
        backgroundColor: 'rgba(16, 142, 233, 1)',
        borderColor: 'rgba(16, 142, 233, 1)',
        hoverBackgroundColor: 'rgba(16, 142, 233, 0.8)'
      },
      { //#62e422
        backgroundColor: 'rgba(98, 228, 34, 1)',
        borderColor: 'rgba(98, 228, 34, 1)',
        hoverBackgroundColor: 'rgba(98, 228, 34, 0.8)'
      },
      // { //#81D4FA
      //   backgroundColor: 'rgba(129, 212, 250, 1)',
      //   borderColor: 'rgba(129, 212, 250, 1)',
      //   hoverBackgroundColor: 'rgba(129, 212, 250, 0.8)'
      // }
    ],
    legend: true,
    type: 'bar'
  };

  constructor(private srms: SimpleRestManagerService, private modalService: NzModalService, private ts: TranslateService, public utilService: UtilService) {
    this.checkWindowSize();
  }

  public ngOnInit(): void {
    let hostname =  window.location.hostname;
    this.subdomain = hostname.split('.')[0];
    this.platform = hostname.split('.')[1];

    this.isLoading = true;
    this.srms.getStatistics(this.subdomain).subscribe(response => {
      this.isLoading = false;
      this.statistics = response;
      this.fillData();
    }, error => {
      this.isLoading = false;
      this.noData = true;
    });
	}

  private checkWindowSize(): void{
    if(window.innerWidth < 740){
      this.lineChart.options.aspectRatio = 1;
      this.pieChart.options.aspectRatio = 1;
      this.barChartHorizontal.options.aspectRatio = 1;
      this.barChartVertical.options.aspectRatio = 1;
    }
  }

  private fillData(): void{
    this.yearsList = this.utilService.getYearsList();
    this.sportsList = this.statistics.sports.sort((a, b) => this.compareFn(a, b));
    this.allClubs = this.statistics.clubs.sort((a, b) => this.compareFn(a, b));

    if(this.statistics.clubsCount || this.statistics.athletesCount) {
      this.lineChart.summary = {
        name: this.statistics.name,
        clubsCount: this.utilService.formatNumber(this.statistics.clubs.length, false),
        athletesCount: this.utilService.formatNumber(this.statistics.clubs.reduce((acc:any, x:any) => acc + x.athletesCount, 0), false)
      };
      this.lineChart.data[0].label = this.ts.instant('statistics.labels.clubCount');
      this.lineChart.data[1].label = this.ts.instant('statistics.labels.athletesCount');
      this.fillLineChartData();
    }
    if(this.statistics.sportFunds) {
      this.pieChart.summary = {
        name: this.statistics.name,
        allocatedSum: this.statistics.sportFunds[moment().year()] ? this.utilService.formatNumber(this.statistics.sportFunds[moment().year()].all.allocated) : '0',
        paidSum: this.statistics.sportFunds[moment().year()] ? this.utilService.formatNumber(this.statistics.sportFunds[moment().year()].all.paid) : '0',
        justifiedSum: this.statistics.sportFunds[moment().year()] ? this.utilService.formatNumber(this.statistics.sportFunds[moment().year()].all.justified) : '0',
        sportsCount: this.utilService.formatNumber(this.statistics.sports.length, false),
        currency: this.utilService.getYearCurrency(moment().year())
      };
      this.fillPieChartData();
    }
    if(this.statistics.clubFunds) {
      this.barChartHorizontal.summary = {
        name: this.statistics.name,
        allocatedSum: this.statistics.clubFunds[moment().year()] ? this.utilService.formatNumber(this.statistics.clubFunds[moment().year()].all.reduce((acc:any, x:any) => acc + x.allocated, 0)) : '0',
        paidSum: this.statistics.clubFunds[moment().year()] ? this.utilService.formatNumber(this.statistics.clubFunds[moment().year()].all.reduce((acc:any, x:any) => acc + x.paid, 0)) : '0',
        justifiedSum: this.statistics.clubFunds[moment().year()] ? this.utilService.formatNumber(this.statistics.clubFunds[moment().year()].all.reduce((acc:any, x:any) => acc + x.justified, 0)) : '0',
        clubsCount: this.utilService.formatNumber(this.statistics.clubs.length, false),
        currency: this.utilService.getYearCurrency(moment().year())
      };
      this.fillBarChartHorizontalData();
    }
    if(this.statistics.labelFunds) {
      this.barChartVertical.summary = {
        name: this.statistics.name,
        allocatedSum: this.statistics.labelFunds[moment().year()] ? this.utilService.formatNumber(this.statistics.labelFunds[moment().year()].all.all.reduce((acc:any, x:any) => acc + x.allocated, 0)) : '0',
        paidSum: this.statistics.labelFunds[moment().year()] ? this.utilService.formatNumber(this.statistics.labelFunds[moment().year()].all.all.reduce((acc:any, x:any) => acc + x.paid, 0)) : '0',
        justifiedSum: this.statistics.labelFunds[moment().year()] ? this.utilService.formatNumber(this.statistics.labelFunds[moment().year()].all.all.reduce((acc:any, x:any) => acc + x.justified, 0)) : '0',
        labelsCount: this.statistics.labelFunds[moment().year()] ? this.utilService.formatNumber(this.statistics.labelFunds[moment().year()].all.all.length, false) : '0',
        currency: this.utilService.getYearCurrency(moment().year())
      };
      this.fillBarChartVerticalData();
    }
  }

  private fillLineChartData(): void{
    let selectedSport = this.lineChart.selectedSport ? this.lineChart.selectedSport.toString() : 'all';

    this.lineChart.data[0].data = this.statistics.clubsCount[selectedSport] ? Object.values(this.statistics.clubsCount[selectedSport]) : [];
    this.lineChart.data[1].data = this.statistics.athletesCount[selectedSport] ? Object.values(this.statistics.athletesCount[selectedSport]) : [];
    this.lineChart.labels = this.statistics.clubsCount[selectedSport] ? Object.keys(this.statistics.clubsCount[selectedSport]) : [];
  }

  private fillPieChartData(): void{
    let selectedYear = this.pieChart.selectedYear;
    let status = this.pieChart.selectedStatus ? (this.pieChart.selectedStatus === 1 ? 'paid' : 'justified') : 'allocated';

    let yearData = {...this.statistics.sportFunds[selectedYear]};
    if(yearData) {
      delete yearData.all;
      let valuesAll = Object.values(yearData);
      valuesAll.sort((a: any, b: any) => b.allocated - a.allocated);
      if(valuesAll.length > 9){
        let values = valuesAll.slice(0, 9);
        let valuesOther = valuesAll.slice(9);
        let otherSum = valuesOther.reduce((acc:any, x:any) => acc + x[status], 0);

        this.pieChart.data = values.map(item => item[status]);
        this.pieChart.data.push(otherSum);
        this.pieChart.labels = values.map(item => item['name']);
        this.pieChart.labels.push('Ostali');
      }else{
        this.pieChart.data = valuesAll.map(item => item[status]);
        this.pieChart.labels = valuesAll.map(item => item['name']);
      }
    }else{
      this.pieChart.data = [];
      this.pieChart.labels = [];
    }
  }

  private fillBarChartHorizontalData(): void{
    let selectedYear = this.barChartHorizontal.selectedYear;
    let selectedSport = this.barChartHorizontal.selectedSport ? this.barChartHorizontal.selectedSport.toString() : 'all';

    let yearData = this.statistics.clubFunds[selectedYear];
    if(yearData) {
      let valuesAll = yearData[selectedSport] ? Object.values(yearData[selectedSport]) : [];
      valuesAll.sort((a: any, b: any) => b.allocated - a.allocated);
      let values = valuesAll.length > 10 ? valuesAll.slice(0, 10) : valuesAll;
      this.barChartHorizontal.data[0].data = values.map(item => item['allocated']);
      this.barChartHorizontal.data[1].data = values.map(item => item['paid']);
      this.barChartHorizontal.data[2].data = values.map(item => item['justified']);
      this.barChartHorizontal.labels = values.map(item => item['name']);
    }else{
      this.barChartHorizontal.data[0].data = [];
      this.barChartHorizontal.data[1].data = [];
      this.barChartHorizontal.data[2].data = [];
      this.barChartHorizontal.labels = [];
    }
  }

  private fillBarChartVerticalData(): void{
    let selectedYear = this.barChartVertical.selectedYear;
    let selectedSport = this.barChartVertical.selectedSport ? this.barChartVertical.selectedSport.toString() : 'all';
    let selectedClub = this.barChartVertical.selectedClub ? this.barChartVertical.selectedClub.toString() : 'all';

    let yearData = this.statistics.labelFunds[selectedYear];
    if(yearData) {
      let valuesAll = yearData[selectedSport] ? (yearData[selectedSport][selectedClub] ? Object.values(yearData[selectedSport][selectedClub]) : []) : [];
      valuesAll.sort((a: any, b: any) => b.allocated - a.allocated);
      let values = valuesAll.length > 10 ? valuesAll.slice(0, 10) : valuesAll;
      this.barChartVertical.data[0].data = values.map(item => item['allocated']);
      this.barChartVertical.data[1].data = values.map(item => item['paid']);
      this.barChartVertical.data[2].data = values.map(item => item['justified']);
      this.barChartVertical.labels = values.map(item => item['name']);
    }else{
      this.barChartVertical.data[0].data = [];
      this.barChartVertical.data[1].data = [];
      this.barChartVertical.data[2].data = [];
      this.barChartVertical.labels = [];
    }
  }

  public getClubsList(): Array<any> {
    let clubs = [];
    clubs = this.barChartVertical.selectedSport ? this.allClubs.filter(item => item.sportId === this.barChartVertical.selectedSport) : this.allClubs;

    return clubs;
  }


  // events
  public changeLineChartFilter(): void {
    this.fillLineChartData();
  }
  public changePieChartFilter(): void {
    let selectedYear = this.pieChart.selectedYear;
    this.pieChart.summary = {
      name: this.statistics.name,
      allocatedSum: this.statistics.sportFunds[selectedYear] ? this.utilService.formatNumber(this.statistics.sportFunds[selectedYear].all.allocated) : '0',
      paidSum: this.statistics.sportFunds[selectedYear] ? this.utilService.formatNumber(this.statistics.sportFunds[selectedYear].all.paid) : '0',
      justifiedSum: this.statistics.sportFunds[selectedYear] ? this.utilService.formatNumber(this.statistics.sportFunds[selectedYear].all.justified) : '0',
      sportsCount: this.utilService.formatNumber(this.statistics.sports.length, false),
      currency: this.utilService.getYearCurrency(selectedYear)
    };
    this.fillPieChartData();
  }
  public changeBarChartHorizontalFilter(): void {
    let selectedYear = this.barChartHorizontal.selectedYear;
    this.barChartHorizontal.summary = {
      name: this.statistics.name,
      allocatedSum: this.statistics.clubFunds[selectedYear] ? this.utilService.formatNumber(this.statistics.clubFunds[selectedYear].all.reduce((acc:any, x:any) => acc + x.allocated, 0)) : '0',
      paidSum: this.statistics.clubFunds[selectedYear] ? this.utilService.formatNumber(this.statistics.clubFunds[selectedYear].all.reduce((acc:any, x:any) => acc + x.paid, 0)) : '0',
      justifiedSum: this.statistics.clubFunds[selectedYear] ? this.utilService.formatNumber(this.statistics.clubFunds[selectedYear].all.reduce((acc:any, x:any) => acc + x.justified, 0)) : '0',
      clubsCount: this.utilService.formatNumber(this.statistics.clubs.length, false),
      currency: this.utilService.getYearCurrency(selectedYear)
    };
    this.fillBarChartHorizontalData();
  }
  public changeBarChartVerticalFilter(): void {
    let selectedYear = this.barChartVertical.selectedYear;
    this.barChartVertical.summary = {
      name: this.statistics.name,
      allocatedSum: this.statistics.labelFunds[selectedYear] ? this.utilService.formatNumber(this.statistics.labelFunds[selectedYear].all.all.reduce((acc:any, x:any) => acc + x.allocated, 0)) : '0',
      paidSum: this.statistics.labelFunds[selectedYear] ? this.utilService.formatNumber(this.statistics.labelFunds[selectedYear].all.all.reduce((acc:any, x:any) => acc + x.paid, 0)) : '0',
      justifiedSum: this.statistics.labelFunds[selectedYear] ? this.utilService.formatNumber(this.statistics.labelFunds[selectedYear].all.all.reduce((acc:any, x:any) => acc + x.justified, 0)) : '0',
      labelsCount: this.statistics.labelFunds[selectedYear] ? this.utilService.formatNumber(this.statistics.labelFunds[selectedYear].all.all.length, false) : '0',
      currency: this.utilService.getYearCurrency(selectedYear)
    };
    this.fillBarChartVerticalData();
  }

  public changePieChartStatus(status: any): void {
    this.pieChart.selectedStatus = status;
    this.fillPieChartData();
  }
  public changeBarChartVerticalSportFilter(): void {
    this.barChartVertical.selectedClub = null;
    this.fillBarChartVerticalData();
  }

  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //console.log(event, active);
  }

  public openSportFundsModal() {
    const modal = this.modalService.create({
      nzTitle: this.ts.instant("statistics.sportFunds"),
      nzContent: SportFundsModal,
      nzComponentParams: {
        data: this.statistics.sportFunds,
        sports: this.sportsList
      },
      nzWidth: 850
    });
    modal.afterClose.subscribe(result => {
    });
  }

  public openClubFundsModal() {
    const modal = this.modalService.create({
      nzTitle: this.ts.instant("statistics.clubFunds"),
      nzContent: ClubFundsModal,
      nzComponentParams: {
        data: this.statistics.clubFunds,
        sports: this.sportsList,
        clubs: this.allClubs
      },
      nzWidth: 850
    });
    modal.afterClose.subscribe(result => {
    });
  }

  public openLabelFundsModal() {
    const modal = this.modalService.create({
      nzTitle: this.ts.instant("statistics.labelFunds"),
      nzContent: LabelFundsModal,
      nzComponentParams: {
        data: this.statistics.labelFunds,
        sports: this.sportsList,
        clubs: this.allClubs
      },
      nzWidth: 850
    });
    modal.afterClose.subscribe(result => {
    });
  }

  public getPlatformColor(): string {
    let color = 'rgba(16, 142, 233, 0.8)';

    switch(this.platform){
      case 'sport': {
        color = 'rgba(16, 142, 233, 0.8)';
        break;
      }
      case 'udruge': {
        color = 'rgba(247, 105, 2, 0.8)';
        break;
      }
    }

    return color;
  }

  public getPlatformImage(): string {
    let image = '../../../../assets/images/SOMSPORT_background.png';

    switch(this.platform){
      case 'sport': {
        image = '../../../../assets/images/SOMSPORT_background.png';
        break;
      }
      case 'udruge': {
        image = '../../../../assets/images/SOMUDRUGE_background.png';
        break;
      }
    }

    return image;
  }

  private compareFn(a, b) {
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }

}
