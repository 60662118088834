import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from '../../../../environments/environment';

/**
 * Abstract class for service implementations
 */
@Injectable()
export abstract class AbstractService {

	/**
	 * Main service URL
	 */
	protected backendServer: string = environment.backendServer;

	constructor(protected http: HttpClient) {

	}

	/**
	 * Converte params object to string
	 * @param <HttpGetRequestParamsInterface> params
	 */
	protected paramsToString(params: HttpGetRequestParamsInterface): string {
		let p: string = '';
		if (params) {
			p = '?';
			let keys = Object.keys(params);
			for (let i in keys) {
				if (p !== '?') {
					p += '&';
				}
				p += encodeURIComponent(keys[i]) + '=' + encodeURIComponent(params[keys[i]]);
			}
		}

		return p;
	}

}

export interface HttpGetRequestParamsInterface {
	[index: string]: string;
}
