import { NgModule } from '@angular/core';

import { OpenCityRouting } from './openCity.routing';
import { CommonModule } from '../common/common.module';

import { MainContainerComponent } from './components/mainContainer/mainContainer.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { HomeComponent } from './components/home/home.component';

import { SportFundsModal } from './modals/sportFunds/sportFunds.modal';
import { ClubFundsModal } from './modals/clubFunds/clubFunds.modal';
import { LabelFundsModal } from './modals/labelFunds/labelFunds.modal';

@NgModule({
  imports: [
    CommonModule,
    OpenCityRouting
  ],

  declarations: [
    MainContainerComponent,
    StatisticsComponent,
    HomeComponent,

    SportFundsModal,
    ClubFundsModal,
    LabelFundsModal
  ],

  exports: [
  ],

  providers: [
  ]
})
export class OpenCityModule { }
