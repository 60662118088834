import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MainRouting } from './main.routing';
import { MainComponent } from './main.component';
import { CommonModule } from '../common/common.module';
import { OpenCityModule } from '../openCity/openCity.module';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,

    MainRouting,
    OpenCityModule
  ],

  declarations: [
    MainComponent
  ],

  providers: [],

  bootstrap: [ MainComponent ]
})
export class MainModule {}
