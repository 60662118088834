<nz-layout>
  <nz-header>
    <div class="title">
      {{ 'home.title' | translate}}
    </div>
  </nz-header>

  <nz-content class="container">
    <nz-spin *ngIf="this.isLoading" nzSimple [nzSize]="'large'"></nz-spin>
    <ng-container *ngIf="this.noData">
      <h1 class="header center mt8">
        {{ 'common.table.noResult' | translate}}
      </h1>
    </ng-container>
    <ng-container *ngIf="this.statistics">
      <div *ngFor="let item of this.statistics">
        <nz-card nzHoverable [nzCover]="coverTemplate" (click)="this.goToStatistics(item.url)" [ngStyle]="{'border-color': item.color}">
          <nz-card-meta [nzTitle]="item.name ? item.name : item.platform" [nzDescription]="item.type"></nz-card-meta>
        </nz-card>
        <ng-template #coverTemplate>
          <div class="imageBox" [ngStyle]="{'border-color': item.color}">
            <img alt="item.type" [src]="item.image" />
          </div>
        </ng-template>
      </div>
    </ng-container>

  </nz-content>

  <nz-footer>
    <div class="text">
      {{ 'home.footerText' | translate}}
    </div>
  </nz-footer>

</nz-layout>
