import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';

@Injectable()
export class UtilService {

  constructor(private ts: TranslateService) {

	}

  /**
	 * Change app language
	 */
  public changeLang(langId: string): void {
    localStorage.setItem("APP_LANG", langId);
    this.ts.use(langId);
    moment.locale(langId);
  }

  /**
   * Get years list
   */
  public getYearsList(): Array<number>{
    let years = [];
    for(let year = 2020; year <= moment().year(); year++ ){
      years.push(year);
    }

    return years;
  }

  /**
   * Format number
   */
   public formatNumber(number: number, decimal: boolean = true): string{
    let decimalLength: number = decimal ? 2 : 0;
    let chunkDelimiter: string = '.';
    let decimalDelimiter:string = ',';
    let chunkLength: number = 3;

		let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
		let pom = number ? number : 0;
    let num = pom.toFixed(Math.max(0, ~~decimalLength));

    return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
	}

  /**
	 * Get year currency
	 */
	public getYearCurrency(year: number): string{
	  return year > 2022 ? 'eur' : 'kn';
	}
}
