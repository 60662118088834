import {Component, OnInit, Input} from '@angular/core';
import * as moment from 'moment';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { UtilService } from 'src/app/common';

@Component({
    selector: 'label-funds-modal',
    templateUrl: 'labelFunds.html',
    styleUrls: ["./labelFunds.scss"]
  })
  export class LabelFundsModal implements OnInit {

    @Input() data: any;

    @Input() sports: any;

    @Input() clubs: any;

    public tableData: Array<any> = [];

    public isLoading: boolean = false;

    public filters = {
      selectedYear: moment().year(),
      selectedSport: 0,
      selectedClub: null,
      search: null
    };

    public yearsList = [];

    constructor(private modal: NzModalRef, public utilService: UtilService) { }

    public ngOnInit() {
      this.yearsList = this.utilService.getYearsList();
      this.changeFilter();
    }

    public changeFilter(): void {
      let selectedYear = this.filters.selectedYear;
      let selectedSport = this.filters.selectedSport ? this.filters.selectedSport.toString() : 'all';
      let selectedClub = this.filters.selectedClub ? this.filters.selectedClub.toString() : 'all';

      let yearData = this.data[selectedYear];
      this.tableData = [];
      if(yearData) {
        let data = yearData[selectedSport] ? (yearData[selectedSport][selectedClub] ? yearData[selectedSport][selectedClub]: []) : [];
        data.sort((a: any, b: any) => b.allocated - a.allocated);
        this.tableData = data.filter(item => this.filters.search ? item.name.toLowerCase().includes(this.filters.search.toLowerCase()) : true);
      }
    }

    public changeSportFilter(): void {
      this.filters.selectedClub = null;
      this.changeFilter();
    }

    public getClubsList(): Array<any> {
      let clubs = [];
      clubs = this.filters.selectedSport ? this.clubs.filter(item => item.sportId === this.filters.selectedSport) : this.clubs;

      return clubs;
    }

    public cancel(): void {
      this.modal.destroy(false);
    }

  }
