import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../abstract/service.abstract.service';

@Injectable()
export class SimpleRestManagerService extends AbstractService {

  /**
	 * Get statistics
	 * @returns <Observable> Observable object
	 */
	public getStatistics(name: string): Observable<any> {
		let url = [this.backendServer, name + '.json'].join('/');
		return <Observable<any>>this.http.get(url);
	}

}
