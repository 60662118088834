import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MODULE_PATH_OPEN_CITY} from './openCity.routes';
import { MainContainerComponent } from './components/mainContainer/mainContainer.component';

const OpenCityRoutes: Routes = [
	{
		path: MODULE_PATH_OPEN_CITY,
		component: MainContainerComponent
	}
];

export const OpenCityRouting: ModuleWithProviders<any> = RouterModule.forRoot(OpenCityRoutes);
