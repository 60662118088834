import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { SimpleRestManagerService, UtilService } from '../../../common';

@Component({
  selector: 'home',
  templateUrl: './home.html',
  styleUrls: ['./home.scss']
})
export class HomeComponent implements OnInit{

  public isLoading: boolean = false;

  public noData: boolean = false;

  public subdomain: string;

  public statistics: any;

  constructor(private srms: SimpleRestManagerService, private ts: TranslateService, public utilService: UtilService) {

  }

  public ngOnInit(): void {
    let hostname =  window.location.hostname;
    this.subdomain = hostname.split('.')[0];

    this.isLoading = true;
    this.srms.getStatistics(this.subdomain).subscribe(response => {
      this.isLoading = false;
      if(response.length){
        this.statistics = response;
      }else{
        this.noData = true;
      }
    }, error => {
      this.isLoading = false;
      this.noData = true;
    });
	}

  public goToStatistics(url: string): void {
    window.open(url, "_blank");
  }

}
